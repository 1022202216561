export const banks = {
    create: {
        url: 'admin/bank/add',
        method: 'POST',
        data: null
    },
    update: {
        url: 'admin/bank/update/',
        method: 'PUT',
        id: null,
        data: null
    },
    get: {
        url: 'admin/bank/get',
        method: 'GET',
        id: null,
        params: null
    },
    delete: {
        url: 'admin/bank/delete/',
        method: 'DELETE',
        id: null
    }
}